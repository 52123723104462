import React from "react";
import { Button, DeleteModal, Table, Text } from "Components";
import { Header } from "Partial/Header/index";
import { useDispatch } from "react-redux";
import { openModal } from "store/slices/modalSlice";
import { icons } from "Assets/icons";
import { useNavigate } from "react-router";
import Status from "Components/Status";
import { path } from "utils";
import BookConsultationPopup from "./components/BookConsultationPopup";
import { BiPlus } from "react-icons/bi";

const BookConsultation = () => {
  const navigate = useNavigate();
  const columns = [
    { name: "Requested On", key: "requestedOn" },
    {
      name: "Booking Number",
      key: "bookingId",
      // render: (data) => dayjs(data?.requestedOn).format("MMM DD YYYY hh:mm A"),
    },
    {
      name: "Service type",
      key: "serviceType",
      render: (data) => data?.consultation?.name,
    },
    {
      name: "Customer Name",
      key: "customerName",
      render: (data) => data?.customer?.name,
    },
    { name: "Start Date ", key: "startDate" },
    { name: "Start Time", key: "startTime" },
    { name: "End Date ", key: "endDate" },
    { name: "End Time", key: "endTime" },
    {
      name: "Status",
      key: "status",
      render: (data) => <Status data={data}>{data?.status}</Status>,
    },
  ];

  const dispatch = useDispatch();

  return (
    <>
      <Header
        name={"Consultation"}
        text={
          <>
            <BiPlus /> Book Consultation
          </>
        }
        buttonOnclick={() =>
          dispatch(
            openModal({
              title: "Book a Consultation",
              content: <BookConsultationPopup />,
            })
          )
        }
        // buttonOnclick={() =>
        //   dispatch(
        //     openModal({
        //       title: "Book consultation",
        //       content: <BookConsultationModal />,
        //     })
        //   )
        // }
      />
      :
      <Table
        column={columns}
        // data={branch}
        appendQuery
        url="/shop/secure/bookings?type=consultation"
        actions={(data) => (
          <div>
            {/* <Button
            bgcolor="inherit "
            w="fit-content"
            onClick={() =>
              navigate(`/accounting/invoice/view-details?code=`)
            }
          >
            {" "}
            <Text color="black">{icons.viewDetail} View Invoice{" "}</Text>
          </Button> */}
            {!data?.invoice?.unique_id && (
              <Button
                bgcolor="inherit "
                w="fit-content"
                onClick={() =>
                  navigate(
                    `/${path}/invoice/create?booking_id=${data?.bookingId}&category=Studio Equipment`
                  )
                }
              >
                {" "}
                <Text color="black"> {icons.plus} Create Invoice </Text>
              </Button>
            )}
            {data?.invoice?.unique_id && (
              <Button
                bgcolor="inherit "
                w="fit-content"
                onClick={() =>
                  navigate(
                    `/${path}/invoice/view-details?invoice_id=${data?.invoice?.unique_id}`
                  )
                }
              >
                {" "}
                <Text color="black">{icons.viewDetail} View Invoice </Text>
              </Button>
            )}
            <Button
              bgcolor="inherit "
              shadow={false}
              color="#EB2F2F"
              onClick={() =>
                dispatch(
                  openModal({
                    title: "Cancel",
                    content: (
                      <DeleteModal
                        method="patch"
                        url={`/shop/secure/bookings/${"studio-equipment"}/${
                          data?.id
                        }/cancel`}
                        successMessage="Booking canceled"
                        payload={{ status: "Canceled" }}
                        btnText="Proceed"
                        message={"Do you want to cancel this booking?"}
                      />
                    ),
                  })
                )
              }
            >
              {" "}
              {icons.delete} Cancel
            </Button>
          </div>
        )}
      />
    </>
  );
};
export default BookConsultation;
