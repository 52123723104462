import { BtnStack } from 'Components'
import React from 'react'

const AvailableList = ({ back = () => null, onAvailable = () => null, children }) => {
    return (
        <div className='flex flex-col gap-3 w-full'>
            {/* {tables?.map(table => <ListItem name={table?.name} onClick={() => { setTable(table?.id); toast.success(`Table ${table.name} selected`) }} />)} */}
            {children}
            <BtnStack leftClick={back} rightClick={onAvailable} />
        </div>

    )
}

export default AvailableList