import { lazy } from "react";
import Approve from "./WorklList/approve";
import Income from "Pages/Accounting/Report/Income";
import InventoryStatement from "Pages/Pos/Reports/InventoryStatement";
import InventoryTransaction from "Pages/Pos/Reports/InventoryTransaction";
import Remittance from "Pages/Pos/Reports/Remittance";
import ReservedAccount from "./MyCustomers/ReservedAccount";
import Invoice from "Pages/Accounting/Invoice";
import CreateInvoice from "Pages/Accounting/Invoice/CreateInvoice";
import InvoiceDetails from "Pages/Accounting/Invoice/InvoiceDetails";
import Funding from "Pages/Pos/Funding";
import SaleMargin from "Pages/Pos/Reports/SaleMargin";
import ManualStockAdjustmentDetails from "./ManualStockAdjustment/ManualStkAdjDeets";
// import RequestInvTransfer from './Inventory/components/RequestInvTransfer';
// import RequestInvTransfer2 from './Inventory/components/InvenTrans';
// import InventoryTransfer from './Inventory/InventoryTransfers';
import ForecastReport from "Pages/Pos/Reports/ForecastReport";
import BookSnooker from "Pages/Pos/Bookings/BookSnooker";
import BookConsultation from "../Pos/Consultation";

const WorklList = lazy(() => import("./WorklList"));
// const Inventory = lazy(() => import('./Inventory'));
const MyCustomers = lazy(() => import("./MyCustomers"));
const Orders = lazy(() => import("./Orders"));
const Shifting = lazy(() => import("./Shifting"));
const AddShift = lazy(() => import("./Shifting/addShift"));
const PurchaseOrder = lazy(() => import("./PurchaseOrder"));
const ManualStockTaking = lazy(() => import("./ManualStockTaking"));
const AddManualStockTaking = lazy(() =>
  import("./ManualStockTaking/AddManualStockTaking")
);
const ManualStockAdjustment = lazy(() => import("./ManualStockAdjustment"));
const AddManualStockAdjustment = lazy(() =>
  import("./ManualStockAdjustment/AddManualStockAdjustment")
);
const AgingReceivable = lazy(() => import("./AgingReceivable"));
const Bookings = lazy(() => import("./Bookings"));
const AddPurchaseOrder = lazy(() => import("./PurchaseOrder/AddPurchaseOrder"));
const PosReserveTable = lazy(() => import("Pages/Pos/Bookings/ReserveTable"));
const PosBookVenue = lazy(() => import("Pages/Pos/Bookings/BookVenue"));
const BookStudioSession = lazy(() =>
  import("Pages/Pos/Bookings/BookStudioSession")
);
const BookMediaProfessionals = lazy(() =>
  import("Pages/Pos/Bookings/BookMediaProfessional")
);
const BookVideoShoot = lazy(() => import("Pages/Pos/Bookings/BookVideoShoot"));
const BookPhotoShoot = lazy(() => import("Pages/Pos/Bookings/BookPhotoShoot"));
const PosBookShortLet = lazy(() => import("Pages/Pos/Bookings/BookShortLet"));
const Hire = lazy(() => import("Pages/Pos/Hire"));
const OperationHome = lazy(() => import("./Home"));

const layout = true;
export const operations_routes = [
  {
    Component: <OperationHome />,
    path: "/operations".toLowerCase(),
    layout,
    isPublic: false,
    permitkey: "",
  },
  {
    Component: <WorklList />,
    path: "/operations/WorkList".toLowerCase(),
    layout,
    isPublic: false,
    permitkey: "",
  },
  {
    Component: <BookConsultation />,
    path: "/operations/Consultation".toLowerCase(),
    layout,
    isPublic: false,
    permitkey: "",
  },
  {
    Component: <Approve />,
    path: "/operations/WorkList/approve/:type/:id/:item_id".toLowerCase(),
    layout,
    isPublic: false,
    permitkey: "",
  },
  // { Component: <Inventory />, path: '/operations/Inventory'.toLowerCase(), layout, isPublic: false, permitkey: '' },
  // { Component: <InventoryTransfer />, path: '/operations/Inventory-transfer'.toLowerCase(), layout, isPublic: false, permitkey: '' },
  // { Component: <InventoryTransfer />, path: '/operations/Inventory-transfer/:id'.toLowerCase(), layout, isPublic: false, permitkey: '' },
  // { Component: <RequestInvTransfer2 />, path: '/operations/Inventory-transfer/add'.toLowerCase(), layout, isPublic: false, permitkey: '' },
  // { Component: <RequestInvTransfer />, path: '/operations/Inventory/add'.toLowerCase(), layout, isPublic: false, permitkey: '' },
  {
    Component: <MyCustomers />,
    path: "/operations/customers".toLowerCase(),
    layout,
    isPublic: false,
    permitkey: "customer",
  },
  {
    Component: <ReservedAccount />,
    path: "/operations/reserved-accounts".toLowerCase(),
    layout,
    isPublic: false,
    permitkey: "",
  },
  {
    Component: <Orders />,
    path: "/operations/Orders".toLowerCase(),
    layout,
    isPublic: false,
    permitkey: "",
  },
  {
    Component: <Orders />,
    path: "/operations/Orders/:id".toLowerCase(),
    layout,
    isPublic: false,
    permitkey: "",
  },
  {
    Component: <Shifting />,
    path: "/operations/Shifting".toLowerCase(),
    layout,
    isPublic: false,
    permitkey: "",
  },
  {
    Component: <AddShift />,
    path: "/operations/Shifting/edit/:id".toLowerCase(),
    layout,
    isPublic: false,
    permitkey: "",
  },
  {
    Component: <Funding />,
    path: "/operations/funding",
    layout,
    isPublic: false,
    permitkey: "",
  },
  // { Component: <AddShift />, path: '/operations/Shifting/add'.toLowerCase(), layout, isPublic: false, permitkey:'' },
  {
    Component: <PurchaseOrder />,
    path: "/operations/PurchaseOrder".toLowerCase(),
    layout,
    isPublic: false,
    // permitkey:'view purchase order'
  },
  {
    Component: <PurchaseOrder />,
    path: "/operations/PurchaseOrder/:id".toLowerCase(),
    layout,
    isPublic: false,
    // permitkey:'view purchase order'
  },
  {
    Component: <AddPurchaseOrder />,
    path: "/operations/PurchaseOrder/add".toLowerCase(),
    layout,
    isPublic: false,
    // permitkey:'create purchase order'
  },
  {
    Component: <ManualStockTaking />,
    path: "/operations/ManualStockTaking".toLowerCase(),
    layout,
    isPublic: false,
    // permitkey:'view manaul stock taking'
  },
  {
    Component: <AddManualStockTaking />,
    path: "/operations/ManualStockTaking/add".toLowerCase(),
    layout,
    isPublic: false,
    // permitkey: 'create manaul stock taking'
  },
  {
    Component: <ManualStockAdjustment />,
    path: "/operations/manual-transactions".toLowerCase(),
    layout,
    isPublic: false,
    permitkey: "view manual transaction",
  },
  {
    Component: <AddManualStockAdjustment />,
    path: "/operations/manual-transactions/add".toLowerCase(),
    layout,
    isPublic: false,
    permitkey: "",
  },
  {
    Component: <ManualStockAdjustmentDetails />,
    path: "/operations/manual-transactions/:id".toLowerCase(),
    layout,
    isPublic: false,
    permitkey: "",
  },
  {
    Component: <AgingReceivable />,
    path: "/operations/AgingReceivable".toLowerCase(),
    layout,
    isPublic: false,
    permitkey: "",
  },
  {
    Component: <Bookings />,
    path: "/operations/Booking".toLowerCase(),
    layout,
    isPublic: false,
    permitkey: "",
  },
  {
    Component: <PosReserveTable />,
    path: "/operations/bookings/table".toLowerCase(),
    layout,
    isPublic: false,
    permitkey: "",
  },
  {
    Component: <PosBookVenue />,
    path: "/operations/bookings/venue".toLowerCase(),
    layout,
    isPublic: false,
    permitkey: "",
  },
  {
    Component: <PosBookShortLet />,
    path: "/operations/bookings/short-let".toLowerCase(),
    layout,
    isPublic: false,
    permitkey: "",
  },
  {
    Component: <Hire />,
    path: "/operations/bookings/studio-equipment".toLowerCase(),
    layout,
    isPublic: false,
    permitkey: "",
  },
  {
    Component: <BookStudioSession />,
    path: "/operations/bookings/studio-session".toLowerCase(),
    layout,
    isPublic: false,
    permitkey: "",
  },
  {
    Component: <BookMediaProfessionals />,
    path: "/operations/bookings/media-service".toLowerCase(),
    layout,
    isPublic: false,
    permitkey: "",
  },
  {
    Component: <BookVideoShoot />,
    path: "/operations/bookings/video-shoot".toLowerCase(),
    layout,
    isPublic: false,
    permitkey: "",
  },
  {
    Component: <BookPhotoShoot />,
    path: "/operations/bookings/photo-shoot".toLowerCase(),
    layout,
    isPublic: false,
    permitkey: "",
  },
  {
    Component: <BookSnooker />,
    path: "/operations/bookings/snooker".toLowerCase(),
    layout,
    isPublic: false,
    permitkey: "",
  },
  {
    Component: <Income />,
    path: "/operations/income-report",
    layout,
    isPublic: false,
    permitkey: "",
  },
  {
    Component: <InventoryStatement />,
    path: "/operations/inventory-statement-report",
    layout,
    isPublic: false,
    permitkey: "inventory report",
  },
  {
    Component: <ForecastReport />,
    path: "/operations/forecast-report",
    layout,
    isPublic: false,
    permitkey: "inventory report",
  },

  {
    Component: <InventoryTransaction />,
    path: "/operations/inventory-transaction-report",
    layout,
    isPublic: false,
    permitkey: "inventory transaction report",
  },
  {
    Component: <SaleMargin />,
    path: "/operations/sales-margin-report",
    layout,
    isPublic: false,
    permitkey: "",
  },
  {
    Component: <Remittance />,
    path: "/operations/remittance",
    layout,
    isPublic: false,
    permitkey: "",
  },
  {
    Component: <Invoice />,
    path: "/operations/invoice",
    layout,
    isPublic: false,
    permitkey: "",
  },
  {
    Component: <CreateInvoice />,
    path: "/operations/invoice/create",
    layout,
    isPublic: false,
    permitkey: "",
  },
  {
    Component: <InvoiceDetails />,
    path: "/operations/invoice/view-details/",
    layout,
    isPublic: false,
    permitkey: "",
  },
];
