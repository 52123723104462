import Invoice from "Pages/Accounting/Invoice";
import CreateInvoice from "Pages/Accounting/Invoice/CreateInvoice";
import { lazy } from "react";
import PosReserveTable from "./Bookings/ReserveTable";
import AgingReceivable from "Pages/Operations/AgingReceivable";
import BookSnooker from "./Bookings/BookSnooker";
import InvoiceDetails from "Pages/Accounting/Invoice/InvoiceDetails";
import BookConsultation from "./Consultation";
// const AgingReceivable = lazy(() => import("./AgingReceivable"));
const PosBookVenue = lazy(() => import("./Bookings/BookVenue"));
// const ReserveTable = lazy(() => import("./Bookings/ReserveTable"));
const PosDashboard = lazy(() => import("./Dashbaord"));
const Hire = lazy(() => import("./Hire"));
const Order = lazy(() => import("./Orders"));
const Funding = lazy(() => import("./Funding"));
const AddOrder = lazy(() => import("./Orders/components/addorder"));
const BookMediaProfessionals = lazy(() =>
  import("./Bookings/BookMediaProfessional")
);
const BookStudioSession = lazy(() => import("./Bookings/BookStudioSession"));
const BookPhotoShoot = lazy(() => import("./Bookings/BookPhotoShoot"));
const BookVideoShoot = lazy(() => import("./Bookings/BookVideoShoot"));
const PosBookShortLet = lazy(() => import("./Bookings/BookShortLet"));
const InventoryStatement = lazy(() => import("./Reports/InventoryStatement"));
const InventoryTransaction = lazy(() =>
  import("./Reports/InventoryTransaction")
);
const BranchSalesReport = lazy(() => import("./Reports/BranchSalesReport"));

const layout = { header: true, sidebar: true };

export const pos_routes = [
  {
    Component: <PosDashboard />,
    path: "/pos/",
    layout,
    isPublic: false,
    permitkey: "",
  },
  {
    Component: <Hire />,
    path: "/pos/hire-studio-equipment",
    layout,
    isPublic: false,
    permitkey: "",
  },
  {
    Component: <Order />,
    path: "/pos/orders",
    layout,
    isPublic: false,
    permitkey: "",
  },
  {
    Component: <AddOrder />,
    path: "/pos/add-order",
    layout: false,
    isPublic: false,
    permitkey: "",
  },
  {
    Component: <AddOrder />,
    path: "/pos/add-order/:id",
    layout: false,
    isPublic: false,
    permitkey: "",
  },
  {
    Component: <InventoryStatement />,
    path: "/pos/inventory-statement-report",
    layout,
    isPublic: false,
    permitkey: "",
  },
  {
    Component: <InventoryTransaction />,
    path: "/pos/inventory-transaction-report",
    layout,
    isPublic: false,
    permitkey: "",
  },
  {
    Component: <BranchSalesReport />,
    path: "/pos/branch-sales-report",
    layout,
    isPublic: false,
    permitkey: "",
  },
  // { Component: <ReserveTable />, path: '/pos/reserve-table', layout, isPublic: false, permitkey:'' },
  // { Component: <PosBookVenue />, path: '/pos/venue', layout, isPublic: false, permitkey:'' },
  // { Component: <ReserveTable />, path: '/pos/short-let', layout, isPublic: false, permitkey:'' },
  {
    Component: <AgingReceivable />,
    path: "/pos/agingreceivable",
    layout,
    isPublic: false,
    permitkey: "",
  },
  {
    Component: <Funding />,
    path: "/pos/funding",
    layout,
    isPublic: false,
    permitkey: "",
  },
  {
    Component: <PosReserveTable />,
    path: "/pos/bookings/table".toLowerCase(),
    layout,
    isPublic: false,
    permitkey: "",
  },
  {
    Component: <PosBookVenue />,
    path: "/pos/bookings/venue".toLowerCase(),
    layout,
    isPublic: false,
    permitkey: "",
  },
  {
    Component: <PosBookShortLet />,
    path: "/pos/bookings/short-let".toLowerCase(),
    layout,
    isPublic: false,
    permitkey: "",
  },
  {
    Component: <Hire />,
    path: "/pos/bookings/studio-equipment".toLowerCase(),
    layout,
    isPublic: false,
    permitkey: "",
  },
  {
    Component: <BookStudioSession />,
    path: "/pos/bookings/studio-session".toLowerCase(),
    layout,
    isPublic: false,
    permitkey: "",
  },
  {
    Component: <BookMediaProfessionals />,
    path: "/pos/bookings/media-service".toLowerCase(),
    layout,
    isPublic: false,
    permitkey: "",
  },
  {
    Component: <BookVideoShoot />,
    path: "/pos/bookings/video-shoot".toLowerCase(),
    layout,
    isPublic: false,
    permitkey: "",
  },
  {
    Component: <BookPhotoShoot />,
    path: "/pos/bookings/photo-shoot".toLowerCase(),
    layout,
    isPublic: false,
    permitkey: "",
  },
  {
    Component: <BookSnooker />,
    path: "/pos/bookings/snooker".toLowerCase(),
    layout,
    isPublic: false,
    permitkey: "",
  },
  {
    Component: <BookConsultation />,
    path: "/pos/Consultation".toLowerCase(),
    layout,
    isPublic: false,
    permitkey: "",
  },

  // { Component: <BookStudioSession />, path: '/pos/book-studio-session', layout, isPublic: false, permitkey:'' },
  // { Component: <BookPhotoShoot />, path: '/pos/book-photoshoot', layout, isPublic: false, permitkey:'' },
  // { Component: <BookVideoShoot />, path: '/pos/book-video-shoot', layout, isPublic: false, permitkey:'' },
  // { Component: <PosBookShortLet />, path: '/pos/book-short-let', layout, isPublic: false, permitkey:'' },
  // { Component: <BookStudioSession />, path: '/pos/book-studio-session', layout, isPublic: false, permitkey:'' },
  // { Component: <BookMediaProfessionals />, path: '/pos/book-professional-media-services', layout, isPublic: false, permitkey:'' },
  {
    Component: <Invoice />,
    path: "/pos/invoice",
    layout,
    isPublic: false,
    permitkey: "",
  },
  {
    Component: <CreateInvoice />,
    path: "/pos/invoice/create",
    layout,
    isPublic: false,
    permitkey: "",
  },
  {
    Component: <InvoiceDetails />,
    path: "/pos/invoice/view-details/",
    layout,
    isPublic: false,
    permitkey: "",
  },
];
