// import { BiSolidBuildingHouse } from 'react-icons/bi'
import { assets } from "../../Assets";
// import { MdOutlineTableRestaurant } from 'react-icons/md'
import { FaWallet } from "react-icons/fa";
// import { pos_icons } from 'Pages/Pos/pos_icon'
// import { icons } from 'Assets/icons'
import { operations_icons as oi } from "Pages/Operations/operationIcons";
const base = "/pos";

export const pos_paths = [
  { path: base + "/", name: "Dashboard", icon: assets.icons.dashboard },
  // { name: 'WorklList', path: `${base}/WorklList`.toLowerCase() },//----
  // {
  //   name: "Orders",
  //   path: `${base}/Orders`.toLowerCase(),
  //   icon: assets.icons.orders,
  // }, //----
  {
    name: "Booking",
    path: `${base}/Booking`.toLowerCase(),
    icon: oi.booking,
    children: [
      // { path: base + "/bookings/table", name: "Tables" },
      // { path: base + "/bookings/venue", name: "Venues" },
      {
        path: base + "/bookings/passport-photograph",
        name: "Passport photograph",
      },
      { path: base + "/bookings/studio-equipment", name: "Studio Equipments" },
      { path: base + "/bookings/studio-session", name: "Studio Sessions" },
      {
        path: base + "/bookings/media-service",
        name: "Professional Media Service",
      },
      { path: base + "/bookings/video-shoot", name: "Video shoot" },
      { path: base + "/bookings/photo-shoot", name: "Photo shoot" },
      { path: base + "/bookings/live-performance", name: "Live performance" },
    ],
  },
  {
    name: "Aging Receivable",
    path: `${base}/AgingReceivable`.toLowerCase(),
    icon: assets.icons.agingReceivable,
  },
  // {
  //   name: "Funding",
  //   path: `${base}/funding`.toLowerCase(),
  //   icon: <FaWallet />,
  // },
  {
    name: "Consultation",
    path: `${base}/Consultation`.toLowerCase(),
    icon: assets.icons.consultation,
  },
  {
    name: "Hire Studio Equipment",
    path: `${base}/hire-studio-equipment`.toLowerCase(),
    icon: oi.worklist,
  },
  { path: base + "/invoice", name: "Invoice", icon: assets.icons.dashboard },
];
// .concat(
//     [
//         {
//             path: `${base}/branch-sales-report`.toLowerCase(), name: 'Branch Sales Report', icon: icons.receipt
//         }
//     ]
// )
