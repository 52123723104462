import axios from "axios";
import { toast } from "react-toastify";
import { store } from "store";
import { check_session } from "utils/check_session";

// FOR DEV
// http://18.130.204.71- frontend
// http://35.180.125.240 - backend

// For Pre-prod
// https://www.be-global.gurugeeksroyalty.net/  - frontend
// https://be-global-api.gurugeeksroyalty.net/  - backend

// For Prod
// https://www.begnh.com/ -frontend
// https://beglobal-api.begnh.com/  -backend

// const origin = window.location.origin;
// const envs  = {
//     dev :
// }

const getbackendurl = () => {
  switch (origin) {
    case "http://localhost:3000":
      return "http://localhost:8000";
    case "https://www.be-global.gurugeeksroyalty.net":
      return "https://be-global-api.gurugeeksroyalty.net";
    case "https://studio-mgmt.gurugeeksroyalty.biz":
      return "https://studio-mgmt-api.gurugeeksroyalty.biz";
    default:
      return "https://be-global-api.gurugeeksroyalty.net";
  }
};

export const baseURL = `${getbackendurl()}/api/v1/`;
export const operations_path = "/operations/";
export const fetcher = axios.create({ baseURL });

export const apiRequest = async ({
  method = "get",
  url,
  payload,
  hasAuth,
  showError = true,
  contentType = "application/json",
  successMeggage,
}) => {
  let updated_url = url;
  check_session();
  const { access_token } = store.getState().user;
  const headers = {
    headers: {
      "Content-Type": contentType,
      Accept: "application/json",
      ...(hasAuth && { Authorization: `Bearer ${access_token}` }),
    },
  };
  // console.log(hasAuth)
  const args =
    method === "get" || method === "delete"
      ? [updated_url, headers]
      : [updated_url, payload, headers];

  try {
    const data = await fetcher[method.toLowerCase()](...args);
    successMeggage && toast.success(successMeggage);
    return data;
  } catch (error) {
    const errors = error?.response?.data?.data?.errors;
    // console.log(error)
    if (errors) {
      let messages = Object.values(errors);

      messages.map((message) => {
        if (message.length) {
          message.map((innerMessage) =>
            toast.error(innerMessage, { toastId: innerMessage })
          );
        } else {
          toast.error(message, { toastId: message });
        }
        return message;
      });
      // return messages
    } else {
      const err = error?.response?.data?.data?.error?.message;
      if (error?.response?.status !== 500) {
        if (err === "Access Token has expired") {
          toast.error(err);
          window.location.replace(origin);
        }
      }
      // return err
    }
    if (error?.response?.status === 401) {
      throw new Error("You are not autorized");
    } else {
      throw error;
    }
  }
};
