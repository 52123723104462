import { lazy } from "react";
import OTPs from "./OTP";

const Accounting = lazy(() => import("../Admin/Accounting/index"));
const ApprovalHierachy = lazy(() => import("../Admin/ApprovalHierachy/index"));
const Branches = lazy(() => import("../Admin/Branches/index"));
const BusinessInfo = lazy(() => import("../Admin/BusinessInfo/index"));
const Discount = lazy(() => import("../Admin/Discount/index"));
const Consultation = lazy(() => import("../Admin/Consultation/index"));
const Membership = lazy(() => import("../Admin/Membership/index"));
const MenuManagement = lazy(() => import("../Admin/Menu/MenuManagement/index"));
const PackageManagement = lazy(() => import("../Admin/Menu/PackageManagement"));
const OrderTypes = lazy(() => import("../Admin/OrderTypes/index"));
const POS = lazy(() => import("../Admin/POS/index"));
const ReferralDiscount = lazy(() => import("../Admin/ReferralDiscount/index"));
const RolesAndPermission = lazy(() =>
  import("../Admin/RolesAndPermission/index")
);
const StaffManagement = lazy(() => import("../Admin/StaffManagement/index"));
const Suppliers = lazy(() => import("../Admin/Suppliers/index"));
const Tables = lazy(() => import("../Admin/Tables/index"));
const VenueManagement = lazy(() => import("../Admin/VenueManagement/index"));
const ShortLetAdjustment = lazy(() =>
  import("../Admin/ShortLetAdjustment/index")
);
const RolesDetails = lazy(() => import("./RolesAndPermission/rolesDetails"));
const MenuDetails = lazy(() =>
  import("../Admin/Menu/MenuManagement/components/menuDetails")
);
const AddMenuItems = lazy(() =>
  import("../Admin/Menu/MenuManagement/components/addMenuItem")
);
const PackageDetails = lazy(() =>
  import("../Admin/Menu/PackageManagement/components/packageDetails")
);
const AddPackageItems = lazy(() =>
  import("../Admin/Menu/PackageManagement/components/addPackageItems")
);
const AccountingTypes = lazy(() => import("../Admin/Accounting/Types"));
const ChartOfAccount = lazy(() =>
  import("../Admin/Accounting/ChartOfAccount/index")
);
const SbuCode = lazy(() => import("./Accounting/SBU"));

const MenuCategory = lazy(() => import("./Menu/MenuManagement/category"));
const MenuIngredients = lazy(() =>
  import("./Menu/MenuManagement/ingreidients")
);
const Shortlet = lazy(() => import("./ShortLet"));
const ProfessionalService = lazy(() => import("./ProfessionalService"));
const StudioEquipment = lazy(() => import("./StudioEquipment"));
const ServicePriceList = lazy(() => import("./ServicePriceList"));
const ShiftMangement = lazy(() => import("./ShiftManagement"));
const InventorySource = lazy(() => import("./InventorySource"));

const layout = { header: true, sidebar: true };
export const admin_routes = [
  {
    Component: <Accounting />,
    path: "/admin/accounting",
    layout,
    isPublic: false,
    permitkey: "view account",
  },
  {
    Component: <ApprovalHierachy />,
    path: "/admin/approval-hierachy",
    layout,
    isPublic: false,
    permitkey: "view hierarchy",
  },
  {
    Component: <Branches />,
    path: "/admin/branches",
    layout,
    isPublic: false,
    permitkey: "view branch",
  },
  {
    Component: <Consultation />,
    path: "/admin/consultation",
    layout,
    isPublic: false,
    permitkey: "view consultaion",
  },
  {
    Component: <BusinessInfo />,
    path: "/admin/business-info",
    layout,
    isPublic: false,
    permitkey: "view business information",
  },
  {
    Component: <BusinessInfo />,
    path: "/admin/business-info/:page",
    layout,
    isPublic: false,
    permitkey: "view business information",
  },
  {
    Component: <Discount />,
    path: "/admin/discount",
    layout,
    isPublic: false,
    permitkey: "view discount",
  },
  // { Component: <InventoryManagement />, path: '/admin/inventory-management', layout, isPublic: false, permitkey: 'view inventory' },
  {
    Component: <InventorySource />,
    path: "/admin/inventory-source",
    layout,
    isPublic: false,
    permitkey: "view inventory house",
  },
  {
    Component: <Membership />,
    path: "/admin/membership",
    layout,
    isPublic: false,
    permitkey: "view membership",
  },
  {
    Component: <MenuManagement />,
    path: "/admin/menu-management",
    layout,
    isPublic: false,
    permitkey: "view menu",
  },
  {
    Component: <MenuCategory />,
    path: "/admin/menu-category",
    layout,
    isPublic: false,
    permitkey: "view category",
  },
  {
    Component: <MenuIngredients />,
    path: "/admin/ingredients",
    layout,
    isPublic: false,
    permitkey: "view ingredient",
  },
  {
    Component: <MenuDetails />,
    path: "/admin/menu-management/menu-details/:id",
    layout,
    isPublic: false,
    permitkey: "view menu",
  },
  {
    Component: <AddMenuItems />,
    path: "/admin/menu-management/add",
    layout: false,
    isPublic: false,
    permitkey: "create menu",
  },
  {
    Component: <AddMenuItems />,
    path: "/admin/menu-management/edit/:id",
    layout: false,
    isPublic: false,
    permitkey: "create menu",
  },
  {
    Component: <PackageManagement />,
    path: "/admin/package-management",
    layout,
    isPublic: false,
    permitkey: "view package",
  },
  {
    Component: <PackageDetails />,
    path: "/admin/package-management/package-details/:id",
    layout,
    isPublic: false,
    permitkey: "view package",
  },
  {
    Component: <AddPackageItems />,
    path: "/admin/package-management/add",
    layout: false,
    isPublic: false,
    permitkey: "create package",
  },
  {
    Component: <AddPackageItems />,
    path: "/admin/package-management/edit/:id",
    layout: false,
    isPublic: false,
    permitkey: "create package",
  },
  {
    Component: <AccountingTypes />,
    path: "/admin/accounting-types",
    layout,
    isPublic: false,
    permitkey: "view accounting type",
  },
  {
    Component: <ChartOfAccount />,
    path: "/admin/chart-of-account",
    layout,
    isPublic: false,
    permitkey: "view accounts",
  },
  {
    Component: <SbuCode />,
    path: "/admin/sbu-code",
    layout,
    isPublic: false,
    permitkey: "view SBU code",
  },
  {
    Component: <OrderTypes />,
    path: "/admin/order-types",
    layout,
    isPublic: false,
    permitkey: "view order type",
  },
  {
    Component: <POS />,
    path: "/admin/pos",
    layout,
    isPublic: false,
    permitkey: "view pos",
  },
  {
    Component: <ReferralDiscount />,
    path: "/admin/referral-bonus",
    layout,
    isPublic: false,
    permitkey: "view referal discount",
  },
  {
    Component: <StaffManagement />,
    path: "/admin/staff-management",
    layout,
    isPublic: false,
    permitkey: "view staff",
  },
  {
    Component: <RolesAndPermission />,
    path: "/admin/roles-and-permission",
    layout,
    isPublic: false,
    permitkey: "view roles",
  },
  {
    Component: <RolesDetails />,
    path: "/admin/roles-details",
    layout,
    isPublic: false,
    permitkey: "view roles",
  },
  {
    Component: <Suppliers />,
    path: "/admin/suppliers",
    layout,
    isPublic: false,
    permitkey: "view supplier",
  },
  {
    Component: <Tables />,
    path: "/admin/tables",
    layout,
    isPublic: false,
    permitkey: "view table",
  },
  {
    Component: <VenueManagement />,
    path: "/admin/venue-management",
    layout,
    isPublic: false,
    permitkey: "view venue",
  },
  {
    Component: <Shortlet />,
    path: "/admin/short-let",
    layout,
    isPublic: false,
    permitkey: "short let",
  },
  {
    Component: <StudioEquipment />,
    path: "/admin/studio-equipment",
    layout,
    isPublic: false,
    permitkey: "view shift",
  },
  {
    Component: <ShiftMangement />,
    path: "/admin/shift-management",
    layout,
    isPublic: false,
    permitkey: "view shift",
  },
  {
    Component: <ProfessionalService />,
    path: "/admin/professional-services",
    layout,
    isPublic: false,
    permitkey: "view media service",
  },
  {
    Component: <ServicePriceList />,
    path: "/admin/service-price-list",
    layout,
    isPublic: false,
    permitkey: "view service price",
  },
  {
    Component: <ShortLetAdjustment />,
    path: "/admin/short-let-adjustment",
    layout,
    isPublic: false,
    permitkey: "view accounts",
  },
  {
    Component: <OTPs />,
    path: "/admin/otps",
    layout,
    isPublic: false,
    permitkey: "view otp",
  },

  // { Component: <Promo />, path: '/admin/promo', layout, isPublic: false },
];
