import { Box } from "@mui/material";
import {
  BtnStack,
  Button,
  FlexText,
  Form,
  Input,
  ProgressStep,
} from "Components";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { changeTitle } from "store/slices/modalSlice";
import ReviewConfirm from "./ReviewConfirm";
import SuccessfulModal from "./Successful";
import { SelectCustomer } from "./AssignCustomer";
import { useBranches } from "hooks";
import { selectOpts, selectPay } from "utils";
import { apiRequest } from "Services";
import dayjs from "dayjs";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import Explore from "./Explore";
import { NearestAvailabe } from "./Nearest";
import { formatDate, formatPrice } from "utils/formatPrice";
import ListItem from "./ListItem";
import AvailableList from "./AvailableList";
import { NearestAvailableList } from "./NearestAvailableList";
import HiveOtpPos from "./HivePinPos";
import { validate_otp } from "Services/otp";
import UpdateEmail from "Components/UpdateEmail";

const BookConsultationPopup = () => {
  const dispatch = useDispatch();
  const [current, setCurrent] = useState(0);
  const [formValues, setFormValues] = useState({});
  const [details, setDetails] = useState({});
  const [consultation, setConsultation] = useState([]);
  const [consultation_id, setConsultationId] = useState(null);
  const { branches } = useBranches();
  const [type, setType] = useState("existing");
  const [duration, setDuration] = useState(0);
  const [total, setTotal] = useState();

  useEffect(() => {
    let start =
      dayjs(formValues?.startDate).format("YYYY-MM-DD") +
      " " +
      dayjs(formValues?.startTime).format("H:mm");
    let end =
      dayjs(formValues?.endDate).format("YYYY-MM-DD") +
      " " +
      dayjs(formValues?.endTime).format("H:mm");

    const date1 = dayjs(start);
    const date2 = dayjs(end);
    const diff = date2.diff(date1, "day", true);

    const days = Math.floor(diff);
    const hours = Math.floor((diff - days) * 24);

    const hourswithmins = (diff - days) * 24;

    const mins = Math.floor((hourswithmins - hours) * 60);
    setDuration(
      `${days ? days + " days, " : ""} ${hours ? hours + " hours" : ""}  ${
        mins ? ", " + mins + " mins" : " "
      } `
    );
    let hrs =
      (days * 24 + hours + mins / 60) *
      parseInt(
        consultation?.find((x) => x?.id === parseInt(consultation_id))?.price
      );
    setTotal(hrs);
  }, [formValues, consultation]);

  const handleChange = (e) => {
    setFormValues((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const { data: methods } = useQuery("/collections/payment-methods");

  useEffect(() => {
    dispatch(changeTitle("Book Consultation"));
  }, []);
  const { data: consultation_list } = useQuery(
    `/shop/secure/bookings/${formValues?.branchId}/available/consultation`,
    { enabled: Boolean(formValues?.branchId) }
  );
  const { data: nearest_consultation } = useQuery(
    `/shop/secure/bookings/${
      formValues?.branchId
    }/nearest-available/consultation?start_date=${formatDate({
      date: formValues?.startDate,
      type: "backenddate",
    })}&end_date=${formatDate({
      date: formValues?.endDate,
      type: "backenddate",
    })}&start_time=${formatDate({
      date: formValues?.startTime,
      type: "backendtime",
    })}&end_time=${formatDate({
      date: formValues?.endTime,
      type: "backendtime",
    })}`,
    {
      enabled: Boolean(
        formValues?.branchId &&
          formValues?.startDate &&
          formValues?.startTime &&
          formValues?.endTime &&
          formValues?.endDate
      ),
    }
  );

  useEffect(() => {
    if (consultation_list?.data?.length) {
      setConsultation(consultation_list?.data);
    } else {
      setConsultation(nearest_consultation?.data);
    }
  }, [consultation_list, nearest_consultation]);

  const submit = async (ref = {}) => {
    const references = ref?.paymentReference
      ? {
          references: ref,
        }
      : {};

    try {
      await apiRequest({
        payload: {
          ...references,
          consultation_id: consultation_id,
          branch_id: parseInt(formValues?.branchId),
          start_date: dayjs(formValues?.startDate).format("YYYY-MM-DD"),
          end_date: dayjs(formValues?.endDate).format("YYYY-MM-DD"),
          start_time: dayjs(formValues?.startTime).format("HH:mm"),
          end_time: dayjs(formValues?.endTime).format("HH:mm"),
          total_amount: total,
          payment_method: formValues?.paymentMethod,
          payment_structure: "Full",
          service: "consultation",
          customer_id: details?.id,
        },
        method: "post",
        url: "/shop/secure/bookings/consultation",
        hasAuth: true,
      });
      setCurrent(4);
      toast.success("Consultation booking successful");
    } catch (error) {}
  };

  const moveOne = () => {
    if (formValues?.endDate >= formValues?.startDate) {
      consultation?.length ? setCurrent(11) : setCurrent(12);
    } else {
      toast.info("Start day can not be greater than end date");
    }
  };
  const One = () => (
    <>
      <Input
        label="Branch"
        placeholder="Select branch"
        type="select"
        name="branchId"
        defaultValue={formValues?.branchId}
        option={selectOpts(branches)}
        onChange={handleChange}
      />

      <Box display="flex" gap={"16px"}>
        <Input
          label="Start date"
          type="date"
          name="startDate"
          defaultValue={formValues?.startDate}
          onChange={handleChange}
        />
        <Input
          label="Start time"
          type="time"
          name="startTime"
          defaultValue={formValues?.startTime}
          onChange={handleChange}
        />
      </Box>
      <Box display="flex" gap={"16px"}>
        <Input
          label="End date"
          type="date"
          name="endDate"
          defaultValue={formValues?.endDate}
          onChange={handleChange}
        />
        <Input
          label="End time"
          type="time"
          name="endTime"
          defaultValue={formValues?.endTime}
          onChange={handleChange}
        />
      </Box>

      <Button onClick={moveOne}>Proceed</Button>
    </>
  );

  return (
    <Form>
      {current < 2 && (
        <ProgressStep points={[1, 2]} current={current} onChange={setCurrent} />
      )}

      {current === 0 && <One />}
      {current === 11 && (
        <AvailableList
          back={() => setCurrent(0)}
          onAvailable={() => {
            if (consultation_id) setCurrent(1);
            else {
              toast.info("Please select a consultation");
            }
          }}
        >
          {consultation?.map((single_consultation) => (
            <ListItem
              active={consultation_id}
              id={single_consultation?.id}
              name={single_consultation?.name}
              onClick={() => {
                setConsultationId(single_consultation?.id);
                toast.success(
                  `consultation ${single_consultation.name} selected`
                );
              }}
            />
          ))}
        </AvailableList>
      )}
      {current === 12 && (
        <Explore
          name={"consultation"}
          back={() => setCurrent(0)}
          onExplore={() => setCurrent(13)}
        />
      )}
      {current === 13 && (
        <NearestAvailableList
          list={consultation?.data}
          back={() => setCurrent(12)}
          onAvailable={(id) => {
            setCurrent(1);
          }}
          name={"Consultation"}
        >
          {nearest_consultation?.data?.map((nearest_consultation) => (
            <ListItem name={nearest_consultation?.name} />
          ))}
        </NearestAvailableList>
      )}
      {current === 1 && (
        <SelectCustomer
          prev={() => setCurrent(0)}
          next={() => setCurrent(2)}
          onChange={setType}
          getCustomer={setDetails}
        >
          {/* 
                    {type === 'existing' && <>
                        <FlexText leftText='Wallet balance' rightText={`₦${details !== null ? details?.balance?.toLocaleString() : '0.00'}`} />


                        {<FlexText leftText=' Wallet balance ' rightText={formatPrice(details?.wallet?.balance)} />}
                        {<FlexText leftText=' Available balance ' rightText={formatPrice(details?.wallet?.balance + details?.wallet?.overdraft)} />}
                        <hr /></>} */}

          {/* <Input label='Payment structure' placeholder='Select payment structure' name='paymentStructure' onChange={handleChange} type='select' option={[
                        { label: 'Full payment', value: 'Full' },
                    ]} /> */}
          <Input
            label="How will you like to pay?"
            placeholder={"Select payment method"}
            name="paymentMethod"
            onChange={handleChange}
            type="select"
            option={[
              ...methods?.data?.map((method) => ({
                label: method,
                value: method,
              })),
            ]}
          />

          <FlexText
            leftText="Consultation Name"
            rightText={
              consultation?.find((x) => x?.id === parseInt(consultation_id))
                ?.name
            }
            rightWeight={"600"}
          />

          <FlexText
            leftText="Duration"
            rightText={duration}
            rightWeight={"600"}
          />

          <FlexText
            leftText="Amount"
            rightText={`NGN ${consultation
              ?.find((x) => x?.id === parseInt(consultation_id))
              ?.price?.toLocaleString()} / hr`}
            rightWeight={"600"}
          />
        </SelectCustomer>
      )}

      {current === 2 && (
        <ReviewConfirm
          prev={() => setCurrent(1)}
          next={async () => {
            await selectPay({
              method: formValues?.paymentMethod,
              amount: total,
              onBehive: () => setCurrent(3),
              customer: details,
              desc: "Consultation Booking",
              next: (ref) => submit(ref),
              onNoEmail: () => setCurrent("updateEmail"),
              purpose: "booking",
            });
          }}
        >
          <FlexText
            leftText="Selected Consultation"
            rightText={
              consultation?.find((x) => x?.id === parseInt(consultation_id))
                ?.name
            }
          />

          <FlexText leftText={"Customer type"} rightText={details?.type} />
          {/* <FlexText leftText={'Membership'} rightText={details?.type} /> */}
          <FlexText
            leftText={"Branch"}
            rightText={
              branches?.find((x) => x?.id === parseInt(formValues?.branchId))
                ?.name
            }
          />
          <FlexText leftText={"Customer name"} rightText={details?.full_name} />
          <FlexText leftText={"Phone"} rightText={details?.phone} />
          {/* <FlexText leftText={'Payment structure'} rightText={formValues?.paymentStructure} /> */}
          <FlexText leftText={"Duration"} rightText={duration} />
          <FlexText
            leftText={"Charge per hour"}
            rightText={`₦${consultation
              ?.find((x) => x?.id === parseInt(consultation_id))
              ?.price?.toLocaleString()}`}
          />
          <FlexText
            leftText={"Total Amount"}
            rightText={`₦${total?.toLocaleString()}`}
          />
        </ReviewConfirm>
      )}

      {current === "updateEmail" && (
        <UpdateEmail
          customer={details}
          onSuccess={async (cus) => {
            await selectPay({
              method: formValues?.paymentMethod,
              amount: total,
              customer: cus,
              desc: "Consultation Booking",
              next: (ref) => submit(ref),
              purpose: "booking",
            });
          }}
        />
      )}

      {current === 3 && (
        <HiveOtpPos
          paymentMethod={formValues?.paymentMethod}
          back={() => setCurrent(2)}
          onProceed={async (otp) => {
            try {
              await validate_otp(details.phone, otp);
              submit();
            } catch (error) {
              console.log(error);
            }
          }}
        />
      )}

      {current === 4 && (
        <SuccessfulModal
          title="Consultation booked successfully"
          message="You have successfully booked this Consultation."
        />
      )}
    </Form>
  );
};

export default BookConsultationPopup;
