import { MdPeopleAlt, MdWidgets } from "react-icons/md";
import { RiMailDownloadLine, RiSendPlaneFill } from "react-icons/ri";
import {
  FaCalculator,
  FaMoneyCheck,
  FaEdit,
  FaWallet,
  FaKeyboard,
} from "react-icons/fa";
import { IoMdGitBranch, IoIosPaperPlane } from "react-icons/io";
import { MdTableRestaurant, MdFoodBank, MdReceiptLong } from "react-icons/md";
import { HiClipboardCheck } from "react-icons/hi";
import {
  RiAwardFill,
  RiMapPinRangeFill,
  RiGroupFill,
  RiUserSharedFill,
} from "react-icons/ri";
import { GiHotMeal } from "react-icons/gi";
import { ImBriefcase } from "react-icons/im";
import { CgClipboard } from "react-icons/cg";
import { AiFillEye } from "react-icons/ai";
import { BiTrash, BiFoodMenu, BiReset, BiSolidDish } from "react-icons/bi";
import { BsFillClipboardCheckFill, BsPlus } from "react-icons/bs";
import { MdOutlineDoNotDisturb, MdDiscount } from "react-icons/md";
import { RxCountdownTimer } from "react-icons/rx";
import { VscCalendar } from "react-icons/vsc";
import { Search } from "@mui/icons-material";
import { FaPersonMilitaryToPerson } from "react-icons/fa6";

const s3_path = (name) =>
  `https://beglobal-preprod.s3.eu-west-3.amazonaws.com/images/${name}`;
export const icons = {
  //  Admin Dashbaord Sidebar icons
  staffMagt: <MdPeopleAlt />,
  rolesPermissions: <RiSendPlaneFill />,
  approvalHierarchy: <RiSendPlaneFill />,
  branches: <IoMdGitBranch />,
  tables: <MdTableRestaurant />,
  pos: <FaCalculator />,
  discount: <FaMoneyCheck />,
  menuMgt: <GiHotMeal />,
  venueMgt: <RiMapPinRangeFill />,
  consultation: <FaPersonMilitaryToPerson />,
  inventoryMgt: <HiClipboardCheck />,
  accounting: <MdReceiptLong />,
  suppliers: <CgClipboard />,
  orderType: <MdFoodBank />,
  memberShip: <RiAwardFill />,
  referral: <IoIosPaperPlane />,
  businessInfo: <ImBriefcase />,

  // Headers icons

  plus: <BsPlus size={20} fontWeight={"700"} />,

  // Actions Modals icons
  viewHistory: <RxCountdownTimer size={24} />,
  viewDetail: <AiFillEye size={24} weight={"200"} />,
  overdraft: <RiMailDownloadLine size={20} weight={"200"} />,
  fundWallet: <FaWallet size={24} />,
  setDiscount: <MdDiscount size={24} weight={"200"} />,
  edit: <FaEdit size={24} fontWeight={"100"} />,
  delete: <BiTrash size={24} fontWeight={"100"} />,
  disable: <MdOutlineDoNotDisturb size={24} fontWeight={"100"} />,
  // money:
  // operations pages
  dashboard: <MdWidgets />,
  bookings: <VscCalendar />,
  inventory: <BsFillClipboardCheckFill />,
  myCustomers: <RiGroupFill />,
  orders: <GiHotMeal />,
  ShiftingMgt: <RiUserSharedFill />,
  purchases: <BiFoodMenu />,
  receipt: <MdReceiptLong />,
  manualStockTaking: <FaKeyboard />,
  agingReceivable: <BiReset />,

  //pos pages
  order: <BiSolidDish />,

  search: <Search />,

  eye: s3_path("icons/eye.svg"),
  facebook: s3_path("icons/facebook.svg"),
  instagram: s3_path("icons/instagram.svg"),
  linkedin: s3_path("icons/linkedin.svg"),
  close: s3_path("icons/close.svg"),
};
