import { lazy } from "react";

const BookPhotoShoot = lazy(() => import("./BookPhotoShoot"));
const BookProfessionalService = lazy(() =>
  import("./BookProfessionMediaService")
);
const BookShortlet = lazy(() => import("./BookShortlet"));
const BookStudioEquipment = lazy(() => import("./BookStudioEquipment"));
const BookStudioSession = lazy(() => import("./BookStudioSession"));
const BookConsultationModal = lazy(() => import("./BookConsultation"));
const BookVideoShoot = lazy(() => import("./BookVideoShoot"));
const CustomerHome = lazy(() => import("./CustomerHome"));
const Order = lazy(() => import("./Order"));
const Scan = lazy(() => import("./Scan"));
const TrackOrder = lazy(() => import("./TrackYourOrder"));

const layout = false;
export const customer_routes = [
  { Component: <CustomerHome />, path: "/customer", layout, isPublic: true },
  { Component: <Scan />, path: "/customer/scan", layout, isPublic: true },
  { Component: <Order />, path: "/customer/order", layout, isPublic: true },
  {
    Component: <Order />,
    path: "/customer/order/:screen",
    layout,
    isPublic: true,
  },
  {
    Component: <BookPhotoShoot />,
    path: "/customer/book-photo-shoot",
    layout,
    isPublic: true,
  },
  {
    Component: <BookPhotoShoot />,
    path: "/customer/book-photo-shoot/:screen",
    layout,
    isPublic: true,
  },
  {
    Component: <BookVideoShoot />,
    path: "/customer/book-video-shoot",
    layout,
    isPublic: true,
  },
  {
    Component: <BookVideoShoot />,
    path: "/customer/book-video-shoot/:screen",
    layout,
    isPublic: true,
  },
  {
    Component: <BookConsultationModal />,
    path: "/customer/book-consultation",
    layout,
    isPublic: true,
  },
  {
    Component: <BookConsultationModal />,
    path: "/customer/book-consultation/:screen",
    layout,
    isPublic: true,
  },
  {
    Component: <BookShortlet />,
    path: "/customer/book-shortlet",
    layout,
    isPublic: true,
  },
  {
    Component: <BookShortlet />,
    path: "/customer/book-shortlet/:screen",
    layout,
    isPublic: true,
  },
  {
    Component: <BookStudioSession />,
    path: "/customer/book-studio-session",
    layout,
    isPublic: true,
  },
  {
    Component: <BookStudioSession />,
    path: "/customer/book-studio-session/:screen",
    layout,
    isPublic: true,
  },
  {
    Component: <BookStudioEquipment />,
    path: "/customer/book-studio-equipment",
    layout,
    isPublic: true,
  },
  {
    Component: <BookStudioEquipment />,
    path: "/customer/book-studio-equipment/:screen",
    layout,
    isPublic: true,
  },
  {
    Component: <BookProfessionalService />,
    path: "/customer/book-professional-services",
    layout,
    isPublic: true,
  },
  {
    Component: <BookProfessionalService />,
    path: "/customer/book-professional-services/:screen",
    layout,
    isPublic: true,
  },
  {
    Component: <TrackOrder />,
    path: "/customer/track-order",
    layout,
    isPublic: true,
  },
  {
    Component: <TrackOrder />,
    path: "/customer/track-order/:screen",
    layout,
    isPublic: true,
  },
];
